import React from 'react'
import Payout from '../../../common/components/templates/PayoutModals/Payout/Payout'
import PayoutDetails from 'common/components/templates/PayoutModals/PayoutDetails'

const PayoutPage = () => {
  return (
    <Payout>
        <PayoutDetails />   
    </Payout>
  )
}

export default PayoutPage