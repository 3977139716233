import React, { useEffect, useState } from "react"
import styles from "../Payout/Payout.module.scss"
import usePayoutBalance from "common/hooks/usePayoutBalance"
import usePayout from "common/hooks/usePayout"
import { useNavigate } from "react-router-dom"
import CardItem from "common/components/molecules/GridCard/GridCard"
import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import Lists from "common/components/molecules/Lists/Lists"
import { useTranslation } from "react-i18next"
import usePayoutPdf from "common/hooks/usePayoutPdf"
import Loader from "common/components/atoms/Loader"

const PayoutDetails = () => {
  const [balanceDetails, setBalanceDetails] = useState<PayoutItem>()
  const [payoutListData, setPayoutListData] = useState<ResultData>()
  const [payoutPdfData, setPayoutPdfData] = useState<any>()
  const [offset, setOffset] = useState(0)
  const { t } = useTranslation()
  const isEnglish = window.location.href.includes("/en/")
  const [payoutId, setPayoutId] = useState<string>("")

  const navigate = useNavigate()

  const { payoutBalance } = usePayoutBalance()
  const { payoutList, isLoading } = usePayout(offset)

  useEffect(() => {
    if (payoutBalance?.result?.length ?? 0 > 0) {
      setBalanceDetails(payoutBalance?.result[0])
    }
  }, [payoutBalance])

  useEffect(() => {
    if (payoutList?.result?.docs.length ?? 0 > 0) {
      setPayoutListData(payoutList?.result)
      setPayoutId(payoutList?.result?.docs[0].payOutId || "") // Ensure a string value is passed to setPayoutId
    }
  }, [payoutList])

  const handleShowMore = () => {
    if (payoutListData) {
      setOffset(payoutListData.page * payoutListData.limit)
    }
  }

  const { payoutPdf } = usePayoutPdf(payoutId === "" ? "" : payoutId)

  useEffect(() => {
    if (payoutPdf) {
      setPayoutPdfData(payoutPdf)
    }
  }, [payoutPdf])

  const downloadPdf = () => {
    const pdfBlob = new Blob([payoutPdfData], { type: "application/pdf" })
    const downloadUrl = URL.createObjectURL(pdfBlob)
    window.open(downloadUrl, "_blank")
    return downloadUrl
  }

  const openPdfPreview = (payoutId: string) => {
    setPayoutId(payoutId)
    downloadPdf()
  }

  return (
    <div className={styles.FpayOut} style={{ width: "100%" }}>
      <div className={styles.gridCardContainer}>
        <CardItem
          title={t("payout.UnpaidAmount")}
          value={balanceDetails?.currentBalance || 0}
        />
        <CardItem
          title={t("payout.TotalTransferAmount")}
          value={balanceDetails?.transferAmount || 0}
          varientText={isEnglish}
        />

        <CardItem
          title={t("payout.TotalViews")}
          value={balanceDetails?.totalViews || 0}
          isCurrency={false}
        />
        <CardItem
          title={t("payout.CostPerView")}
          value={balanceDetails?.unitPrice || 0}
          precision={4}
        />
      </div>

      <div className={styles.btnWrapper}>
        <Button
          className={styles.btn}
          title={`${t("payout.SeeMonthlyPayout")}`}
          onClick={() =>
            navigate(isEnglish ? "/en/payout/monthly" : "/payout/monthly")
          }
        >
          {t("payout.SeeMonthlyPayout")}
        </Button>
      </div>

      <div className={styles.listContainer}>
        <div className={styles.listContainerHeadWrapper}>
          <Text className={styles.listContainerHead}>
            {t("payout.TransferHistory")}
          </Text>
        </div>
        <div className={styles.listItemWrapper}>
          <ul className={styles.listItemContainer}>
            {!isLoading ? (
              payoutListData?.docs?.length ? (
                payoutListData?.docs.map((payoutDetails) => (
                  <Lists
                    key={payoutDetails?._id}
                    date={payoutDetails?.createdAt}
                    value={payoutDetails.amount.toFixed(0)}
                    payoutId={payoutDetails.payOutId}
                    openPdfPreview={() =>
                      openPdfPreview(payoutDetails.payOutId)
                    }
                  />
                ))
              ) : (
                <div style={{ textAlign: "center" }}>No records</div>
              )
            ) : (
              <Loader />
            )}
          </ul>
          {payoutListData?.hasNextPage && (
            <div className={styles.showMore} onClick={handleShowMore}>
              <span>{t("payout.ShowMore")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PayoutDetails
