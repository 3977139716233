import React, { RefObject, MouseEventHandler, ChangeEventHandler } from "react"
import styles from "./RadioInput.module.scss"

interface BankDetails {
  _id: string
  bankName: string
  bankNo: string
}

interface RadioInputProps {
  title: string
  radioRef: RefObject<HTMLInputElement>
  bankDetails: BankDetails | null
  handleOnClick: MouseEventHandler<HTMLDivElement>
  handleonChange: ChangeEventHandler<HTMLInputElement>
}

const RadioInput: React.FC<RadioInputProps> = ({
  title,
  radioRef,
  bankDetails,
  handleOnClick,
  handleonChange,
}) => {
  return (
    <div>
      <p>{title}</p>
      <div className={styles.inputSpanBoxWrapp} onClick={handleOnClick}>
        {/* <input
          type="radio"
          name="selectedBankId"
          onChange={handleonChange}
          value={bankDetails?._id || ""}
          ref={radioRef}
        /> */}
        <span className={styles.bankName}>{bankDetails?.bankName}</span>
        <span className={styles.bankNumber}>{bankDetails?.bankNo}</span>
      </div>
    </div>
  )
}

export default RadioInput
