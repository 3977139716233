import React from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { passwordRegex } from "utils/constant"
import { getErrorMessage } from "utils/helper"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import useChangePassword from "common/hooks/useChangePassword"
import useError from "common/hooks/useErrors"
import Loader from "common/components/atoms/Loader"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const ChangePassword = () => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
    newPassword: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { onChangePassword, isLoading } = useChangePassword()
  const setError = useError((state) => state.setError)
  const navigate = useNavigate()

  const handlePasswordChange = (data: ChangePasswordProps) => {
    onChangePassword(data)
      .then((response) => {
        setError("パスワードが変更されました", "success")
        navigate("/")
      })
      .catch((err: ResponseError) =>
        setError(getErrorMessage(err?.subStatusCode))
      )
  }

  return (
    <FormLayout>
      <Title order={5} color="purple" fw="bold">
        {t("titles.changePassword")}
      </Title>
      <Input
        label={t("fields.currentPassword")}
        placeholder={t("placeholders.currentPassword")}
        id="currentPassword"
        inputProps={{ ...register("currentPassword") }}
        type="password"
        error={errors.currentPassword?.message}
      />
      <div style={{ width: "100%" }}>
        <Text sz="xxs" fw="bold">
          {t("fields.newPassword")}
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          以下の条件を満たしてください
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・6文字以上で入力してください
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・一つ以上文字を含んでください
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・一つ以上数字を含んでください
        </Text>
        <Input
          label={""}
          placeholder={t("placeholders.newPassword")}
          id="newPassword"
          inputProps={{ ...register("newPassword") }}
          type="password"
          error={errors.newPassword?.message}
        />
      </div>
      <div style={{ width: "100%" }}>
        <Button variant="yellow" onClick={handleSubmit(handlePasswordChange)}>
          {isLoading && <Loader />}
          {t("buttons.edit")}
        </Button>
        <Button variant="grey" onClick={() => navigate("/")}>
          {t("buttons.cancel")}
        </Button>
      </div>
    </FormLayout>
  )
}

export default ChangePassword
