import React from 'react'
import Payout from '../../../common/components/templates/PayoutModals/Payout/Payout'
import { AccountSelection } from 'common/components/templates/PayoutModals/AccountSelection'

const PayoutBank = () => {
  return (
    <Payout>
        <AccountSelection />
    </Payout>
  )
}

export default PayoutBank