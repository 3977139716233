import { formatDateToYYYYMMDD } from "utils/helper"
import styles from "./Lists.module.scss"
import { ReactComponent as DownloadIcon } from "common/assets/icons/download-icon.svg"
import { useEffect, useState } from "react"

interface ListsProps {
  key: string
  date: string
  value: string
  payoutId: string
  openPdfPreview: (payOutId: string) => void
}
const Lists: React.FC<ListsProps> = ({
  key,
  date,
  value,
  payoutId,
  openPdfPreview,
}) => {
  const formatCurrency = (value: string) => {
    const isEnglish = window.location.href.includes("/en/")
    const formattedValue = `${isEnglish ? '$' : '¥'}${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    return formattedValue
  }
  const [delayedValue, setDelayedValue] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setDelayedValue(true)
    }, 1000)
  }, [])
  return (
    <li key={key}>
      <span className={styles.titleLeftText}>
        {formatDateToYYYYMMDD(new Date(date))}
      </span>
      <span className={styles.titleCenter}>{formatCurrency(value)}</span>
      <span className={styles.titleRightText}>
        {delayedValue && (
          <DownloadIcon
            onClick={() => openPdfPreview(payoutId)}
            style={{ cursor: "pointer" }}
          />
        )}
      </span>
    </li>
  )
}

export default Lists
