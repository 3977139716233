import useSWR from "swr"
import { useAPI } from "../hooks/useAPI"
import useAuthStore from "store/authStore"

const usePayout = (nextOffset: number = 0) => {
  const userType = useAuthStore((state) => state.userType)
  const isEnglish = window.location.href.includes("/en/")

  const { get } = useAPI()

  const {
    data: payoutList,
    error,
    isLoading,
  } = useSWR<PayOutListResponse>(
    userType === "influencer"
      ? isEnglish
        ? `influencer/en/payout/?limit=5&offset=${nextOffset}`
        : `influencer/payout/?limit=5&offset=${nextOffset}`
      : "",
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    payoutList,
    isLoading,
    error,
  }
}

export default usePayout
