import useSWR from "swr"
import axios, { isAxiosError } from "axios"
import { getCookies, removeCookies } from "utils/cookies"
import useError from "./useErrors"
import { getErrorMessage } from "utils/helper"
import useAuthStore from "store/authStore"

const usePayoutPdf = (id: string) => {
  const setError = useError((state) => state.setError)
  const userType = useAuthStore((state) => state.userType)
  const isEnglish = window.location.href.includes("/en/")

  const getPdf = async (url: string) => {
    try {
      const accessToken = getCookies("accessToken")
      const headers = {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${accessToken}`,
      }
      const document = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        { responseType: "blob", headers }
      )
      return document.data
    } catch (e: any) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          removeCookies("accessToken")
          window.location.href = "/login"
        }
      }
      setError(getErrorMessage("default"))
    }
  }

  const {
    data: payoutPdf,
    error,
    mutate,
    isLoading,
  } = useSWR<any>(
    userType === "influencer" && id
      ? isEnglish
        ? `influencer/en/payout/${id}/pdf`
        : `influencer/payout/${id}/pdf`
      : "",
    getPdf,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  )

  return {
    payoutPdf,
    isLoading,
    error,
    mutateInfluencers: mutate,
  }
}

export default usePayoutPdf
