import React, { useState, useEffect, useRef } from "react"
import styles from "../Payout/Payout.module.scss"
import useError from "common/hooks/useErrors"
import { useNavigate } from "react-router-dom"
import RadioInput from "common/components/atoms/RadioInput/RadioInput"
import Button from "common/components/atoms/Button"
import Title from "common/components/atoms/Title"
import { useTranslation } from "react-i18next"
import usePayoutBank from "common/hooks/usePayoutBank"

const AccountSelection = () => {
  const [bankDetails, setBankDetails] = useState<PayoutBank>()
  const [selectedBank, setSelectedBank] = useState("")
  const setError = useError((state) => state.setError)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { payoutBank } = usePayoutBank()
  const isEnglish = window.location.href.includes("/en/")

  useEffect(() => {
    if (payoutBank?.result?.bankName !== undefined) {
      setBankDetails(payoutBank.result)
    }
  }, [payoutBank])

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setSelectedBank(event.target.value)
  }

  const handleOnBankEditClick = () => {
    // if (selectedBank === "") {
    //   setError("口座を選択してください")
    //   return
    // }
    navigate(isEnglish ? "/en/payout/bank/edit" : "/payout/bank/edit")
  }

  const radioRef = useRef<HTMLInputElement>(null)

  const handleDivClick = () => {
    if (radioRef.current) {
      radioRef.current.checked = true
      setSelectedBank(bankDetails?._id || "")
    }
  }

  return (
    <div className={styles.accountSelectionWrapper}>
      <div className={styles.container}>
        <Title className={styles.mainTitle}>
          {t("payout.AccountSelectionTitle")}
        </Title>
        {/* TODO: removed radio handle callback differently */}
        {bankDetails?.bankNo !== undefined && (
          <div className={styles.bankInfoWrapper}>
            <RadioInput
              radioRef={radioRef}
              title={t("payout.RegistrationSelection")}
              bankDetails={bankDetails}
              handleonChange={handleChange}
              handleOnClick={handleDivClick}
            />
          </div>
        )}
        <div className={styles.btnWrapper}>
          {bankDetails?.bankNo !== undefined ? (
            <Button
              className={styles.btn}
              title={t("payout.EditAccountInformation")}
              onClick={handleOnBankEditClick}
            >
              {t("payout.EditAccountInformation")}
            </Button>
          ) : (
            <Button
              className={styles.btn}
              title={t("payout.RegisterANewAccount")}
              onClick={() =>
                navigate(isEnglish ? "/en/payout/bank/add" : "/payout/bank/add")
              }
            >
              {t("payout.RegisterANewAccount")}
            </Button>
          )}
        </div>
        <div
          className={styles.bottomtext}
          onClick={() => navigate(isEnglish ? "/en/payout" : "/payout")}
        >
          <span>{t("payout.GoBack")}</span>
        </div>
      </div>
    </div>
  )
}

export default AccountSelection
