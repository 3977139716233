import React, { useEffect } from "react"
import useError from "common/hooks/useErrors"
import Text from "common/components/atoms/Text"
import Button from "common/components/atoms/Button"
import styles from "./DashboardEn.module.scss"
import Loader from "common/components/atoms/Loader"
import FormLayout from "common/components/templates/FormLayout"
import usePreviewPdf from "common/hooks/en_hooks/usePreviewPdf"
import useProfile from "common/hooks/en_hooks/useProfile"
import useInfluencersStoreEn from "store/influencersStoreEn"
import { useNavigate } from "react-router-dom"

const DashboardEn = () => {
  const { pdfData, isLoading, error } = usePreviewPdf()
  const { contractorDetails } = useProfile()
  const setCurrentInfluencer = useInfluencersStoreEn(
    (state) => state.setCurrentInfluencer
  )
  const setError = useError((state) => state.setError)
  const navigate = useNavigate()

  useEffect(() => {
    if (error) setError(error)
    if (contractorDetails)
      setCurrentInfluencer(contractorDetails.data.influencer)
  }, [error, setError, contractorDetails, setCurrentInfluencer])

  const openPdfPreview = () => {
    if (pdfData) {
      const pdfBlob = new Blob([pdfData], { type: "application/pdf" })
      const downloadUrl = URL.createObjectURL(pdfBlob)
      window.open(downloadUrl, "_blank")
      return downloadUrl
    }
  }

  return (
    <FormLayout>
      {/* <Text fw="bold" sz="xs" ta="center" color="red">
        *Official LINE registration is required for contract notification.
      </Text>
      <img
        src="../assets/icons/contract-sign.png"
        alt="qr-code"
        className={styles.qrCode}
      />
      <a
        href="https://lin.ee/QT5TLA3"
        className={styles.lineLink}
        target="_blank"
        rel="noreferrer"
      >
        Click here for official LINE
      </a> */}
      <Button
        variant="yellow"
        icon={"../assets/icons/open-tab.svg"}
        iconPosition={!isLoading ? "right" : undefined}
        onClick={openPdfPreview}
        disabled={isLoading}
      >
        {isLoading && <Loader />}Check contract
      </Button>
      <Button
        style={{ marginTop: 0 }}
        variant="purple"
        onClick={() => navigate("/en/payout")}
      >
        Payout
      </Button>
      <Button
        style={{ marginTop: 0 }}
        variant="grey"
        onClick={() => navigate("/en/payout/bank")}
      >
        Bank
      </Button>
    </FormLayout>
  )
}

export default DashboardEn
