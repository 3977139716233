import useSWR from "swr"
import { useAPI } from "./useAPI"
import useAuthStore from "store/authStore"

const usePayoutBalance = () => {
  const { get } = useAPI()
  const userType = useAuthStore((state) => state.userType)
  const isEnglish = window.location.href.includes("/en/")

  const {
    data: payoutBalance,
    error,
    isLoading,
  } = useSWR<PayoutBalanceResponse>(
    userType === "influencer"
      ? isEnglish
        ? `/influencer/en/payout/balance`
        : `/influencer/payout/balance`
      : "",
    get,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    payoutBalance,
    isLoading,
    error,
  }
}

export default usePayoutBalance
